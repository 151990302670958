import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  // base: import.meta.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: HomeView
    },
    {
      path: '/contact',
      name: 'contact',
      component: () => import('../views/ContactUs.vue')
    },
    {
      path: '/address',
      name: 'address',
      component: () => import('../views/Address.vue')
    },
    {
      path: '/market/:id',
      name: 'market',
      component: () => import('../views/Market.vue')
    },
    {
      path: '/aboutUs',
      name: 'aboutUs',
      component: () => import('../views/AboutUs.vue')
    },
    {
      path: '/news',
      name: 'news',
      component: () => import('../views/News.vue')
    },
    {
      path: '/showNew',
      name: 'showNew',
      component: () => import('../views/showNews.vue')
    },
    // {
    //   path: '/mark_show',
    //   name: 'mark_show',
    //   component: () => import('../views/MarkShow.vue')
    // },
    {
      path: '/hr',
      name: 'hr',
      component: () => import('../views/Hr.vue')
    },
    {
      path: '/honor',
      name: 'honor',
      component: () => import('../views/Honor.vue')
    },
    // {
    //   path: '/investor',
    //   name: 'investor',
    //   component: () => import('../views/Investor.vue')
    // },
    {
      path: '/investorService',
      name: 'investorService',
      component: () => import('../views/investorService.vue')
    },
    {
      path: '/legalNotice',
      name: 'legalNotice',
      component: () => import('../views/legalNotice.vue')
    },
    {
      path: '/copyright',
      name: 'copyright',
      component: () => import('../views/copyright.vue')
    },
    {
      path: '*', // 通用路由，匹配任何未定义的路径
      component: HomeView // 自定义的404组件
    }
  ]
})

export default router
