<template>
  <div>
    <!-- 轮播图 -->
    <swiper class="swiper" style="margin-top: -72px;" :options="swiperOption" ref="mySwiper" v-if="lunbo_list.length > 0">
      <swiper-slide v-for="(item, index) in lunbo_list" :key="index">
        <a target="_self" :href="item.url">
          <img :src="item.img_src" alt="" style="width: 100%;" />
          <div class="content">
            <p>{{ item.desc }}</p>
          </div>
        </a>
      </swiper-slide>
      <div class="swiper-pagination" slot="pagination"></div>
    </swiper>
    <!-- 轮播图 end-->

    <!-- 产品线5个 -->
    <div class="middle">

      <div class=" has-text-centered is-size-3 is-size-4-mobile	 block " >{{Lang[lang]['changjing'] }}</div>
      <swiper class=" swiper_product" :options="swiperOption_product">
        <swiper-slide v-for="(item, index) in productList" :key="index">
          <a target="_self" :href="item.url">
            <a :href="item.url" target="_blank">
              <div>
                <div style="overflow: hidden;" class="is-relative ">
                  <img :src="item.src" alt="" class=" wrap-img modelOne_hover">
                  <p class="has-text-centered  pointer bg_text"
                    :class="{ 'is-size-5': !is_mobile, 'is-size-6': is_mobile }">
                    {{ item.text }}
                    <!-- <i class=" fa fa-solid fa-angle-right"></i> -->
                  </p>
                </div>
              </div>
            </a>
          </a>
        </swiper-slide>
  
        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
        <!-- <div class="swiper-pagination swiper-pagination-product" slot="pagination"></div> -->
      </swiper>
    </div>


    <div class="middle">
      <div class=" has-text-centered	 is-size-3 is-size-4-mobile block" >{{Lang[lang]['solution']}}
        </div>
 
      <swiper class=" " :options="swiperOption_solution" v-if="solutionList.length>0">
        <swiper-slide v-for="(item, index) in solutionList_wrap" :key="index">
          <div class="columns is-multiline ">
            <div class="column is-one-third-desktop " v-for="(item,index) in solutionList.slice(index*6,index*6+6)" style="box-sizing: border-box;">
                <div class="relative">
                  <img :src="item['src']" alt="" style="height: 250px;width: 100%;"><p  :class="{ 'is-size-5': !is_mobile, 'is-size-6': is_mobile }">{{item['text']}}</p>
                </div>
              </div>
          </div>
        </swiper-slide>
  
        <div class="swiper-button-prev swiper-button-prev-solution" slot="button-prev"></div>
        <div class="swiper-button-next swiper-button-next-solution" slot="button-next"></div>
        <!-- <div class="swiper-pagination swiper-pagination-product" slot="pagination"></div> -->
      </swiper>
          <!-- <div class="columns is-multiline ">
    
            <div class="column is-one-third-desktop " v-for="(item,index) in solutionList">
              <div class="relative">
                <img :src="item['src']" alt="" style="height: 250px;width: 100%;"><p  :class="{ 'is-size-5': !is_mobile, 'is-size-6': is_mobile }">{{item['text']}}</p>
              </div>
            </div>
        </div> -->
      </div>

      <!-- 模板二 -->
      <!-- <ModelTwo :modelTwoData="modelTwoData" :is_mobile="is_mobile" :title="Lang[lang]['solution']"></ModelTwo> -->
      <!-- 模板二 end-->

      <ModelEleven :modelData="newsData" :is_mobile="is_mobile" :title="Lang[lang]['news_center']"></ModelEleven>


    </div>
</template>
<script setup>
import { onMounted, ref, reactive } from 'vue'
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
import "aos/dist/aos.css";
import AOS from "aos/dist/aos.js";
import { isMobile } from '@_/util/function.js'

import '@_/assets/css/home.less'
import ModelEleven from '../components/modelEleven/index.vue';
import { EventBus, EventConstance } from '@_/util/bus.js'
import Lang_ from "../assets/lang/lang.js"

import { httpGet } from '@_/util/http.js'
import dataApi from '@_/util/dataApi.js'
import { useRouter, useRoute } from 'vue-router/composables'
const route = useRoute()
const Lang = reactive(Lang_)
const lang = ref(localStorage.getItem('lang') || 'cn')
const is_mobile = ref(isMobile())
const solutionList_wrap = ref([])
const swiperOption_solution = ref({
  speed: 1000,
  loop: true,
  autoplay: {
    // 自动切换的时间间隔，单位ms
    delay: 4000,
    // 用户操作swiper之后，是否禁止autoplay
    disableOnInteraction: false,
  },

  navigation: {
    nextEl: '.swiper-button-next-solution',
    prevEl: '.swiper-button-prev-solution'
  },

})

const swiperOption_product = ref({
  speed: 1000,
  loop: true,
  autoplay: {
    // 自动切换的时间间隔，单位ms
    delay: 3000,
    // 用户操作swiper之后，是否禁止autoplay
    disableOnInteraction: false,
  },
  slidesPerView: isMobile()?1:4,
  spaceBetween: 20,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev'
  },

})
const swiperOption = ref({
  speed: 1000,
  loop: true,
  autoplay: {
    // 自动切换的时间间隔，单位ms
    delay: 3000,
    // 用户操作swiper之后，是否禁止autoplay
    disableOnInteraction: false,
  },
  pagination: {
    el: ".swiper-pagination", //分页器
    clickable: true,
  }
})
const imgList = ref([
])



const modelTwoData = ref([
  { src: '', url: '', text: '' },
  { src: '', url: '', text: '' },
  { src: '', url: '', text: '' },
  { src: '', url: '', text: '' },
  { src: '', url: '', text: '' },
])

const newsData = ref([

])



onMounted(() => {

  AOS.init({
    easing: 'ease-out-back',
    duration: 1000
  });
  get_lunboList()
  get_news()
  get_productList()
  get_solutions()

  EventBus.$on(EventConstance.LANG, (l) => {
    lang.value = l
    if (route.name == 'home') {

      get_lunboList()
      get_news()
      get_productList()
      get_solutions()
    }
  })

})

//获取新闻前六条
const get_news = () => {
  const param = {
    p: 1,
    display: 1,
    newstype: 0
  }
  httpGet(dataApi['get_news'], param)
    .then(data => {


      const tempArr = data.list.map(item => {
        return {
          id: item.id,
          title: item.title,
          text: item.description,
          date: item.created_at.substr(0, 10),
          src: item.img_src

        }
      })
      newsData.value = tempArr.slice(0, 6)
      const newIds = data.list.map(item => item.id)
      localStorage.setItem('newIds', JSON.stringify(newIds))
    })
}

//获取轮播图数据
const lunbo_list = ref([])
const get_lunboList = () => {
  httpGet(dataApi['get_swipe_list'])
    .then(data => {
      lunbo_list.value = data.swips
    })
}

const productList = ref([])
const get_productList = () => {
  httpGet(dataApi['get_productLine'])
    .then(data => {
      const tempArr = data.list
      productList.value = tempArr.map(item => {
        return {
          src: item.img_src,
          text: item.name,
          url: item.url,
          id:item.id
        }
      })
    })
}

const solutionList = ref([])
const get_solutions = () => {
  httpGet(dataApi['get_solution'])
    .then(data => {
      const tempArr = data.list
      solutionList_wrap.value = new Array(Math.ceil(data.list.length/6))
      solutionList.value = tempArr.map(item => {
        return {
          src: item.img_src,
          text: item.name,
          url: item.url,
        }
      })
    })
}



</script>

<style scoped></style>
